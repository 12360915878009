import React from "react";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center"}}>
    <Spin indicator={antIcon} />
  </div>;
}

export default Loading;
