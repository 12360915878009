import React, {FC} from "react";
import {Button, Form, Input} from "antd";

interface OnSubmitFunc {
  (values: LoginFormValues): void;
}

interface LoginFormProps {
  loading: boolean;
  onSubmit: OnSubmitFunc;
}

export interface LoginFormValues {
  username?: string;
  password?: string;
}


const LoginForm: FC<LoginFormProps> = ({onSubmit, loading}: LoginFormProps) => {
  const [form] = Form.useForm();
  const onFinish = (): void => {
    onSubmit(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item name="username" label="Kullanıcı adı" rules={[{ required: true, message: 'Kullanıcı adı giriniz' }]}>
        <Input autoComplete="off" />
      </Form.Item>
      <Form.Item name="password" label="Parola" rules={[{ required: true, message: 'Parola giriniz' }]}>
        <Input type={"password"}/>
      </Form.Item>
      <Form.Item style={{textAlign: 'center'}}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Giriş
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;