import React, {useEffect} from "react";
import {Redirect} from 'react-router';
import {useDispatch, useSelector} from "react-redux";
import { removeAuth } from 'reducers/auth'
import {RootState} from "../../store";
import Loading from "../../components/Loading";

const Index = () => {
  const isAuth = useSelector<RootState>(state => state.auth.isAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) dispatch(removeAuth());
  }, [isAuth, dispatch])

  if (!isAuth) return <Redirect to="/" />;

  return <Loading />
};

export default Index;